#guia-lnd .slider-squares {

	margin-top: 32px;

	h3 {
		margin: 0 0 24px;
		font-weight: 600;
		font-size: 28px;
		line-height: 36px;
		color: #333333;
	}

	em {
		display: block;
		color: #6D7280;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
	}

	picture {
		display: block;
		margin-bottom: 14px;
	}

	a.button {

		margin-top: 24px;
		color: inherit;

		&,
		span {
			width: 100%;
			justify-content: center;
		}
	
	}

	.swiper {

		overflow: initial !important;

		&-slide {
			height: initial !important;
		}
	
	}

	.slider-item {

		display: flex;
		flex-direction: column;
		width: 379px;
		padding: 32px 24px;
		background: #FEFEFE;

		div {
			flex: 1;
		}

	}
	
}

@media( max-width: 480px ){
	
	#guia-lnd .slider-squares {

		h3 {
			font-size: 24px;
			line-height: 34px;
		}

		picture {
			max-width: 224px;
			margin: 0 auto 14px;
		}
	
		.slider-item {
			width: 100%;
		}
	
	}

}

$timeTrans: 4s;

#guia-lnd div.hero {

	position: relative;
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;

	> div {

		display: block;
		position: relative;
		overflow: hidden;
		background: #EE00A8;

		h2,
		> div:nth-of-type(1),
		> div:nth-of-type(2) span:nth-child(1),
		> div:nth-of-type(2) span:nth-child(2) {
			animation-duration: $timeTrans;
			animation-timing-function: linear;
			animation-delay: 1.5s;
			animation-fill-mode: forwards;
		}

		h2 {

			position: absolute;
			left: 388px;
			top: 50%;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: -60px 0 0;
			font-size: 68px;
			font-weight: 850;
			line-height: 60px;
			text-align: center;
			opacity: 0;
			z-index: 10;

			strong {

				position: relative;
				color: #EE00A8;

				span {
					display: block;
					position: relative;
					white-space: nowrap;
					z-index: 5;
				}

				&:before {
					content: '';
					display: block;
					position: absolute;
					top: -12px;
					right: -17px;
					bottom: 3px;
					left: -17px;
					background: #FFDD02;
					clip-path: polygon(0 11px, 100% 0, 100% 100%, 0% 100%);
					z-index: 1;
				}

				&:nth-of-type(2):before {
					top: 2px;
				}
			
			}
		
		}

		> div {

			&:nth-of-type(1) {

				position: absolute;
				bottom: 0;
				left: 0;
				display: flex;
				width: 100%;
				background: #FFDD02;
				z-index: 20;
				opacity: 0;

				em {
					flex: 1;
					font-family: 'Alfabet';
					font-size: 30px;
					font-weight: 500;
					line-height: 34px;
					text-align: left;
					font-style: normal;
				}
			
			}

			&:nth-of-type(2) {

				display: flex;

				span {

					position: relative;
					overflow: hidden;
					background: no-repeat center center;
					background-size: cover;

					&:nth-child(1){
						z-index: 1;
					}
					&:nth-child(2){
						z-index: 5;
					}

				}

			}

		}	

	}

}

@media( max-width: 1080px ){

	#guia-lnd div.hero {
		margin-top: 0;
	}

}


@media( max-width: 980px ){

	#guia-lnd div.hero {
	
		> div {

			h2 {
				font-size: 48px;
				line-height: 40px;
			}

			> div:nth-of-type(1) em {
				font-size: 24px;
				line-height: 28px;
			}

		}
	
	}

}

@media( max-width: 740px ){

	#guia-lnd div.hero {
	
		> div {

			h2 {

				font-size: 32px;
				line-height: 30px;

				strong {

					&:before {
						top: -8px;
						right: -3px;
						bottom: 3px;
						left: -3px;
						clip-path: polygon(0 8px, 100% 0, 100% 100%, 0% 100%);
					}

					&:nth-child(2):before{
						clip-path: none;
						top: 0;
						bottom: -3px;
					}

				}
			
			}

			> div:nth-of-type(1) em {
				font-size: 17px;
				line-height: 24px;
			}

		}
	
	}

}


@media( min-width: 541px ){
	
	#guia-lnd div.hero {

		padding: 0 56px 23px;

		> div {

			h2 {
				animation-name: heroTitle;
			}

			button.styled span {
				height: 56px;
			}

			> div {

				&:nth-of-type(1) {
					justify-content: space-between;
					align-items: center;
					gap: 24px;
					padding: 70px 4.7945205479% 36px;
					clip-path: polygon(0 35px, 100% 0, 100% 100%, 0% 100%);
					animation-name: heroContent;
				}

				&:nth-of-type(2) {

					span {

						display: block;
						width: 50%;
						height: 500px;

						&:nth-child(1){
							background-image: url('../images/hero-left.jpg');
							animation-name: heroPicture1;
						}
						&:nth-child(2){
							background-image: url('../images/hero-right.jpg');
							background-position: center top;
							animation-name: heroPicture2;
						}

					}

				}

			}

		}

	}

}

@media( max-width: 1024px ){

  	#guia-lnd div.hero {
    	padding-left: 24px;
    	padding-right: 24px;
  	}

}

@media( max-width: 540px ){

	#guia-lnd div.hero {

		height: calc( 86dvh - 80px);
		padding: 24px;

		> div {

			height: 100%;

			h2 {
				left: 90%;
				margin-top: -130px;
				transform: translateX(-50%);
				animation-name: heroMobTitle;
			}

			> div {

				&:nth-of-type(1) {

					flex-direction: column;
					gap: 16px;
					padding: 42px 33px 16px;
					clip-path: polygon(0 38px, 100% 0, 100% 100%, 0% 100%);
					animation-name: heroMobContent;

					button.styled {
						&,
						span {
							width: 100%;
							justify-content: center;
						}
						span {
							padding: 0 30px;
						}
					}

				}

				&:nth-of-type(2) {

					flex-direction: column;
					height: 100%;

					span {

						width: 100%;
						height: 50%;

						&:nth-child(1){
							animation-name: heroMobPicture1;
							background-image: url('../images/hero-top.jpg');
						}
						&:nth-child(2){
							animation-name: heroMobPicture2;
							background-image: url('../images/hero-bottom.jpg');
						}

					}

				}
				
			}
		
		}
  	
  	}
	
}

@media( max-width: 480px ){

	#guia-lnd div.hero {
		height: calc( 86dvh - 72px);
  	}
	
}

// Structure
@import 'structure/fonts';
@import 'structure/normalize';
@import 'structure/vars';
@import 'structure/base';
@import 'structure/animations';
@import 'structure/menu-toggle';
@import 'structure/header';
@import 'structure/footer';

// Components
@import 'components/loader-mask';
@import 'components/button';
@import 'components/slick';
@import 'components/slider';
@import 'components/networks';
@import 'components/header-section';
@import 'components/hero';
@import 'components/alimente-se-bem';
@import 'components/alimentacao-saudavel';
@import 'components/list-squares';
@import 'components/cadastre-se';
@import 'components/headed-squares';
@import 'components/slider-squares';
@import 'components/verdades';
@import 'components/classificacao';
@import 'components/outros-trabalhos';
@import 'components/biblioteca';
@import 'components/inicio';
@import 'components/ta-na-mesa';
@import 'components/quem-somos';
@import 'components/newsletter';

// Internet explorer
//@import 'structure/ie';

@media( min-width: 733px ){
    body .just-mobile {
        display: none !important;
    }
}
@media( max-width: 732px ){
    body .hide-mobile {
        display: none !important;
    }
}
#guia-lnd section.biblioteca {

	.slider-item {

		display: flex;
		flex-direction: column;
		width: 379px;
		padding: 24px;
		border: 1px solid #E3E4E8;
		background: #FEFEFE;
		height: initial !important;

		div {
			flex: 1;
			padding-bottom: 11px;
			margin-bottom: 16px;
			border-bottom: 1px solid #E3E4E8;
		}

		h3 {
			margin: 0 0 8px;
			font-weight: 700;
			font-size: 18px;
			line-height: 27px;
			color: #333333;
		}

		a.button {

			&,
			span {
				text-decoration: none;
			}

			span {
				height: auto;
				padding: 0;
			}

			&:hover svg g path {
				fill: #8c004c;
			}
			
		}

		em {
			display: block;
			margin-bottom: 8px;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #6D7280;
		}
	
	}

}
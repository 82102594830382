#guia-lnd section.ta-na-mesa {
	
	div {
		position: relative;
		display: flex;
		align-items: center;
		gap: 24px;
		padding: 16px 46px 16px 24.7431506849%;
		background: #E5007E;
	}

	h2 {
		margin: 0;
		font-weight: 500;
		font-size: 27px;
		line-height: 32px;
		letter-spacing: -0.01em;
		color: #FEFEFE;
	}

	button.styled span {
		height: 56px;
		padding: 0 12px 0 14px;
		font-size: 20px;
	}
	
	.graph {

		position: absolute;
		top: 50%;
		left: 0;
		width: 24.7431506849%;
		z-index: 10;
		transform: translateY(-50%);

		span {

			padding-top: 74.4642857143%;

			&:before {
				background-image: url('../images/ta-na-mesa-small.png');
			}
		
		}

	}
	
}

@media( max-width: 1060px ){
	
	#guia-lnd section.ta-na-mesa {

		padding-top: 120px;

		div {

			padding: 88px 32px 32px 32px;
		}

		.graph {
			top: 0;
			left: 50%;
			width: 278px;
			transform: translate(-50%,-55%);
		}

	}

}

@media( max-width: 768px ){
	
	#guia-lnd section.ta-na-mesa h2 {
		font-size: 25px;
		line-height: 32px;
	}

}

@media( max-width: 700px ){
	
	#guia-lnd section.ta-na-mesa {

		div {
			flex-direction: column;
			padding: 143px 16px 27px;
		}

		.graph {
			transform: translate(-50%, -38%);
		}
	
	}

}

@media( max-width: 480px ){
	
	#guia-lnd section.ta-na-mesa {

		padding-top: 46px;

		div {
			flex-direction: column;
			padding: 143px 16px 27px;
		}

		.graph {
			transform: translate(-50%, -38%);
		}

		button.styled {
			&,
			span {
				width: 100%;
				justify-content: center;
			}
		}
	
	}

}

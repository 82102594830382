#guia-lnd section#quem-somos {

	display: flex;
	align-items: flex-start;
	gap: 32px;

	h2 {
		margin-bottom: 24px;
		text-align: left;
	}

	a.button {
		margin-top: 24px;
	}

	.image-wrapper {

		&.desktop {
			width: 39.5547945205%;
		}
		&.mobile {
			display: none;
		}

	}

	.content {
		flex: 1;
	}

}

@media( max-width: 640px ){

	#guia-lnd section#quem-somos {
	
		.image-wrapper {

			&.desktop {
				display: none;
			}
			&.mobile {
				display: block;
				padding: 10px 0 20px;
			}

		}

	}
	
}

@media( max-width: 420px ){

	#guia-lnd section#quem-somos {
	
		a.button {

			&,
			span {
				width: 100%;
				justify-content: center;
			}

		}

	}
	
}

#guia-lnd {

	section.alimente-se-bem {
		padding-bottom: 0;
	}

	.banner-passos {
	
		position: relative;
		display: flex;
		align-items: center;
		max-width: 1168px;
		margin: 50px auto 0;
		color: #FEFEFE;

		> div.wrapper {
			position: relative;
			display: block;
			width: 100%;
			margin-top: 10px;
		}

		h3 {
			margin: 16px 0 0;
			font-family: 'Alfabet';
			font-weight: 700;
			font-size: 32px;
			line-height: 32px;
			color: #000000;

		}

		em {
			margin-top: 32px;
			font-weight: 400;
			font-size: 18px;
			line-height: 27px;
			color: #000000;
		}

		cite {

			display: block;
			margin-top: 32px;
			text-align: left;
			font-size: 18px;
			line-height: 27px;
			color: #6D7280;
			font-style: normal;
			font-weight: 400;

			a {
				color: inherit;
			}
		
		}

		picture,
		img {
			display: block;
		}

		footer {
			position: absolute;
			right: 0;
			bottom: 46px;
			left: 0;
			padding-left: calc( 50% + 56px );
			z-index: 99;
		}

		.slider-wrapper {
			margin: 0;
		}

		.slider-item {

			display: flex !important;
			align-items: center;
			gap: 56px;

			.image-wrapper {
				width: 50%;
				min-width: 50%;
			}

			.content {

				flex: 1;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				height: 396px;

				> strong {
					display: table;
					height: 26px;
					padding: 0 10px;
					font-family: 'Alfabet';
					font-style: normal;
					font-weight: 400;
					font-size: 20px;
					line-height: 26px;
					text-transform: uppercase;
					color: #FFFFFF;
					background: #E5007E;
				}
			
			}
		
		}
		
		.swiper-slide {
	        
	        opacity: 0!important;
	        transition-duration: .8s !important;

	        &.swiper-slide-active {
	            opacity: 1 !important;
	        }

	    }

		.slider-arrows {
			position: absolute;
			top: 62px;
			right: 0;
			display: flex;
			align-items: center;
			gap: 16px;
			z-index: 50;
		}

	}

}

@media( max-width: 1279px ){
	
	#guia-lnd .banner-passos {

		flex-wrap: wrap;

		h3 {
			margin-top: 30px;
		}

		footer {

			position: relative;
			right: initial;
			left: initial;
			bottom: initial;
			display: flex;
			flex-direction: column-reverse;
			width: 100%;
			padding: 0;

			cite {
				margin-top: -24px;
				padding-left: calc(50% + 36px);
			}
			
		}

		.slider-item {

			align-items: flex-start;
			gap: 36px;

			.content {
				height: auto;
			}
		
		}

		.slider-arrows {
			top: 0;
		}
	
	}

}

@media( max-width: 1126px ){
	
	#guia-lnd .banner-passos footer cite {
		margin-top: -52px;
	}

}

@media( max-width: 980px ){

	
	#guia-lnd .banner-passos {

		padding-bottom: 32px;
	
		h3 {
			font-size: 24px;
			line-height: 32px;
		}

		em {
			margin-top: 16px; 
			font-size: 16px;
  			line-height: 24px
		}

		footer {

			margin-top: 24px;
		
			cite {
				padding: 0;
				margin: 0;
				text-align: center;
			}
		
		}

		.slider-item {
			gap: 24px;
		}

	}

}

@media( max-width: 767px ){
	
	#guia-lnd {

		section.alimente-se-bem .images-spaces {

			position: relative;
			display: block;
			width: 100%;
			max-width: 480px;
			margin-top: 24px;

			span {

				position: relative;
				display: block;
				width: 100%;
				max-width: initial;

				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 0;
					padding-top: 93.8888888889%;
				}
				
			}
			
		}

		.banner-passos {

			margin-top: 0;
			max-width: 480px;

			em {
				font-size: 18px;
				line-height: 27px;
			}

			.slider-item {

				flex-direction: column;
				gap: 0;
				padding-top: 24px;

				.image-wrapper {
					position: absolute;
					bottom: 100%;
					width: 100%;
					max-width: initial;
				}
			
			}

			.slider-arrows {
				top: 24px;
			}

			.slider-dots {
				margin-top: 0;
			}

			footer {
				flex-direction: column;
				gap: 32px;
				margin-top: 32px;
			}

		}
	
	}

}

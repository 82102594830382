#guia-lnd header.header-section {

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;

	svg {
		margin-bottom: 16px;
	}

	h2 {
		margin: 0;
		text-align: center;
	}

	em {
		display: block;
		color: #6D7280;
		text-align: center;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 27px;
	}

}

@media( max-width: 767px ){

	#guia-lnd header.header-section em {

		font-size: 16px;
		line-height: 24px;

		br {
			display: none;
		}
	
	}

}
#guia-lnd section#inicio {

	padding-top: 0;

	div.intro-desc {
		padding-top: 56px;
	}

	div.intro {

		display: flex;
		align-items: flex-start;
		padding-top: 82px;

		h2 {
			margin-bottom: 32px;
		}

		p {
			font-size: 18px;
			line-height: 27px;
			color: #000000;
		}

		img {
			display: block;
		}

		picture.mobile {
			display: none;
		}

		> div,
		> picture {
			width: 50%;
		}
		> div {
			padding: 0 56px;
		}
	
	}

	div.riscos {

		position: relative;
		display: flex;
		flex-direction: column;
		gap: 48px;
		padding-top: 56px;

		h3 {
			margin: 0;
		}

		.graph {

			right: -15.2559055118%;
			bottom: 0;
			width: 79.3307086614%;

			span {

				padding-top: 70.2002355713%;

				&:before {
					display: none;
				}

				svg {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			
			}
		
		}

	}

}

@media( max-width: 1166px ){

	#guia-lnd section#inicio div.intro {

		padding-top: 56px;
	
		> div {
  			padding-left: 0;
  		}

	}

}

@media( max-width: 768px ){
	
	#guia-lnd section#inicio {

		div.intro {

			padding-top: 32px;
			padding-bottom: 32px;

			h2 {
				margin-bottom: 24px;
			}

			p {

				font-size: 16px;
				line-height: 24px;

				&:not(:last-of-type){
					margin-bottom: 22px;
				}
			
			}

			> div {
				width: 100%;
				padding: 0;
			}

			picture {

				&.mobile {

					display: block;
					margin-bottom: 24px;

					img {
						width: 100%;
					}
				
				}
				&.desktop {
					display: none;
				}

			}

		}

	}

}

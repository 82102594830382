#guia-lnd .networks {

    gap: 16px;
    display: flex;

    a {

        position: relative;
        width: 65px;
        height: 64px;
        overflow: hidden;
        text-indent: -999vw;
        transition: all .4s ease-in-out;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: no-repeat center center;
            transition: all .4s ease-in-out;
       	}
       	&:before {
       		background-image: url('../svg/bg-button-share.svg');
            background-size: contain;
       		filter: invert(76%) sepia(96%) saturate(1113%) hue-rotate(359deg) brightness(105%) contrast(107%);
       	}
        &:after {
            filter: invert(18%) sepia(93%) saturate(3520%) hue-rotate(314deg) brightness(84%) contrast(118%);
        }

        &.facebook:after {
            background-image: url('../svg/share-facebook.svg');
        }
        &.instagram:after {
            background-image: url('../svg/share-instagram.svg');
        }
        &.twitter:after {
            background-image: url('../svg/share-twitter.svg');
        }
        &.whatsapp:after {
            background-image: url('../svg/share-whatsapp.svg');
        }
        &.tiktok:after {
            background-image: url('../svg/share-tiktok.svg');
        }
        &.youtube:after {
            background-image: url('../svg/share-youtube.svg');
        }
        &.email:after {
            background-image: url('../svg/share-e-mail.svg');
        }

        &:hover {

        	&:before {
        		filter: invert(17%) sepia(36%) saturate(6292%) hue-rotate(310deg) brightness(73%) contrast(115%);
        		transform: rotate(5deg)
        	}

            &:after {
            	filter: brightness(0) invert(1);
            	transform: rotate(-5deg)
            }

        }
    
    }

    &.big a {
        
        width: 84px;
        height: 82px;

        &:after {
            transform: scale(1.6);
        }

    }

    &.medium a {
        
        width: 64px;
        height: 64px;

        &:after {
            transform: scale(1.2);
        }

    }

}
#guia-lnd .alimentacao-saudavel  {
	
	@extend .center;
	padding-top: 0;

	.intro {
		
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;

		h2 {
			flex-basis: 100%;
			text-align: left;
		}

		p {
			font-size: 18px;
			line-height: 27px;
		}


	}

	.content {
		flex: 1;
			padding-right: 9%;
	}

	.figure {

		position: relative;
		transform: translateX(-50px);
		
		svg {

			position: absolute;

			&:nth-child(1){
				left: 0;
				animation: questOne 6s linear infinite;
			}
			&:nth-child(2){
				top: 0;
				animation: questTwo 6s linear infinite;
			}
			&:nth-child(3){
				right: 0;
				animation: questTree 6s linear infinite;
			}
		
		}
	
	}
	
}

@media( max-width: 1024px ){
	
	#guia-lnd .alimentacao-saudavel .figure {
		transform: translate(-20px,100px);
	}
	
}

@media( max-width: 845px ){
	
	#guia-lnd .alimentacao-saudavel {

		.intro {
		
			flex-direction: column;
			align-items: center;
			gap: 32px;

			h2 {
				margin: 0;
				text-align: center;
				order: 2;
			}

		}

		.figure {
			order: 1;
			transform: none;
			margin-bottom: 32px;
		}

		.content {
			order: 3;
			padding-right: 0;
		}

	}

}

@media( max-width: 540px ){

	#guia-lnd .alimentacao-saudavel {

		.intro {

			h2 {
				font-size: 32px;
				line-height: 32px;
			}
			
			p {
				font-size: 16px;
				line-height: 24px;
			}

		}

		.figure {
  			margin-bottom: 0;
			transform: scale(.6);
		}

		.content {
  			padding-right: 0;
		}

  	}

}

@media( min-width: 421px ){

	#guia-lnd .alimentacao-saudavel .figure {

		width: 374px;
		height: 294px;
		
		svg {

			position: absolute;

			&:nth-child(1){
				top: 52px;
				animation: questOne 6s linear infinite;
			}
			&:nth-child(2){
				left: 72px;
				animation: questTwo 6s linear infinite;
			}
			&:nth-child(3){
				bottom: 6px;
				animation: questTree 6s linear infinite;
			}
		
		}
	
	}

}

@media( max-width: 420px ){
	
	#guia-lnd .alimentacao-saudavel .figure {

		width: 148px;
		height: 115px;
		
		svg {

			position: absolute;

			&:nth-child(1){
				top: 22px;
				width: 29px;
				height: 42px;
			}
			&:nth-child(2){
				left: 46px;
				width: 82px;
				height: 106px;
			}
			&:nth-child(3){
				bottom: 6px;
				width: 34px;
				height: 39px;
			}
		
		}
	
	}

}

#guia-lnd .list-squares {

	margin-top: 32px;

	h3 {
		margin: 0;
		color: #333;
		font-family: 'Archivo';
		font-size: 20px;
		font-weight: 700;
		line-height: 100%;
	}

	.icon-wrapper {
		width: 104px;
		min-width: 104px;
	}

	.slider-item {

		padding: 28px 24px;
		border: 1px solid #E3E4E8;
		background: #FEFEFE;

		> div {

			display: flex;
			align-items: center;

			> div {
				flex: 1;
				display: flex;
				flex-direction: column;
				gap: 12px;
			}
			
		}

		em {
			color: #6D758F;
			font-size: 16px;
			font-style: normal;
			line-height: 24px;
		}

		cite {
			
			color: #6D758F;
			text-edge: cap;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 24px;
			text-decoration-line: none;

			a {
				color: inherit;
			}

		}
	
	}
	
}

@media( max-width: 940px ){

	#guia-lnd .list-squares {

		h3 {
			text-align: center;
		}

		svg {
			margin-bottom: -4px;
		}

		.icon-wrapper {
			width: auto;
			min-width: initial;
			margin-bottom: 16px;
		}

		.slider-item {

			padding: 16px 26px;

			> div {
				flex-direction: column;
			}
			
		}

	}
	
}

@media( min-width: 640px ){

	#guia-lnd .list-squares {

		> div {
			display: flex;
			flex-wrap: wrap;
			gap: 16px;
		}

		.slider-item {
			width: calc(50% - 8px)
		}

	}
	
}

@media( max-width: 639px ){

	#guia-lnd .list-squares {

		padding: 0 0 35px;
		margin-top: 32px;

		.slider {
			border: 1px solid #e3e4e8;
		}
		
		.slider-item {
			padding: 24px;
			border: 0
		}

		.slider-dots {
			margin-top: 24px;
		}

	}
	
}
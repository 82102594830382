#modal-newsletter {

	position: fixed;
	top: 0;
	left: 0;
	width: 100dvw;
	height: 100dvh;
	padding: 0 24px;
	background: rgba( 0, 0, 0, 0.35 );
	z-index: 9999999;
	overflow-x: hidden;
	overflow-y: auto;
	opacity: 0;
	transform: translateX(-200dvw);
	transition: 
		opacity .5s ease-in-out,
		transform 1ms ease .5s
	;

	h2 {
	    margin: 0 0 4px;
		font-family: "Alfabet";
		font-weight: 700;
		font-size: 24px;
		line-height: 34px;
		color: #333333;
		text-align: center;
	}

	header em {
		font-style: normal;
		font-size: 14px;
		line-height: 21px;
		color: #6D7280;
	}

	button.close {
		position: absolute;
		top: 23px;
		right: 26px;
		padding: 0;
		border: 0;
		background: transparent;
	    cursor: pointer;
	}

	fieldset {
		padding: 0;
		margin: 16px 0 0;
		border: 0;
	}

	input {

		&[type="text"],
		&[type="email"]{

			width: 100%;
			height: 40px;
			padding: 0 16px;
			border: 1px solid #E3E4E8;
			line-height: 38px;
			font-size: 16px;

			&.error {
				border-color: #B20D30 !important;
				box-shadow: 0px 0px 0px 2px rgba(178, 13, 48, 0.05);
			}
		
		}
	
	}

	button.submit {

		margin-top: 16px;

		&,
		span {
			width: 100%;
			justify-content: center;
		}

		span {
			height: 63px;
		}

		&:disabled,
		&.disabled {

			opacity: 0.3;
			
			span {
				background-color: gray;
			}
		
		}
	
	}

	> div {

		display: block;
		width: 100%;
		min-height: 100dvh;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 24px 0;

		> div {
			position: relative;
			width: 100%;
			max-width: 609px;
			padding: 0 32px;
			background: #FFF;
			box-shadow: 0 5px 20px rgba( 0, 0, 0, 0.25 );
		}

	}

	div.signup {
		display: block;
		padding-top: 127px;
		padding-bottom: 24px;
	}

	div.message {

		display: none;
		padding-top: 81px;
		padding-bottom: 40px;

		svg {
			display: none;
			margin: 0 auto;
		}

		h2 {
			margin: 56px 0 0;
			font-family: 'Archivo';
			font-weight: 700;
			font-size: 40px;
			line-height: 50px;
			color: #333333;
		}

		em {
			display: block;
			margin-top: 16px;
			font-size: 16px;
			line-height: 150%;
			text-align: center;
			color: #6D7280;
		}

		&.success svg.success {
			display: block;
		}

		&.error svg.error {
			display: block;
		}

	}

	.item-check {

		i {

			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
			border: 1px solid #E3E4E8;
			border-radius: 4px;
			width: 16px;
			min-width: 16px;
			height: 16px;
			transition: all .3s ease-in-out;

			&:before {
				display: block;
				content: '';
				width: 100%;
				height: 100%;
				background: url('../svg/checkbox.svg') no-repeat center center;
				background-size: 16px 16px;
				opacity: 0;
				transition: all .3s ease-in-out;
			}
		
		}

		em {

			color: #333333;
			font-style: normal;
			font-weight: 500;
			font-size: 11px;
			line-height: 20px;
			text-align: left;

			a {
				color: #2563EB;
			}
		
		}

		label {

			display: flex;
			align-items: center;
			gap: 8px;
			cursor: pointer;
			transition: all .3s ease-in-out;

			&:hover i {

				border-color: #E5007E;

				&:before {
					background-color: #004562;
				}

			}
		
		}

		input {

			position: fixed;
			right: 5000dvw;

			&:focus + label {

				i {
					border-color: #E5007E;
					box-shadow: 0px 0px 0px 2px rgba(178, 13, 48, 0.05);
				}

			}

			&.error:not(:checked) + label {

				i {
					border-color: #B20D30;
					box-shadow: 0px 0px 0px 2px rgba(178, 13, 48, 0.05);
				}

				em {
					color: #B20D30;
				}

			}

			&:checked + label i {

				border-color: #EB609E;

				&:before {
					opacity: 1;
				}
			
			}

		}

	}

	.field-item {

		&.email {

			position: relative;
			margin-top: 8px;

			svg {
				position: absolute;
				top: 50%;
				left: 17px;
				transform: translateY(calc(-50% + 1px));
			}

			input {
				padding-left: 44px;
			}
		
		}

		&.item-check {

			margin-top: 4px;

			&.news {
				margin-top: 16px;
			}
		
		}

	}

	.graph {

		position: absolute;
		top: -22px;
		left: 50%;
		width: 184px;
		z-index: 10;
		transform: translateX(-50%);

		span {

			padding-top: 74.4642857143%;

			&:before {
				background-image: url('../images/ta-na-mesa-small.png');
			}
		
		}

	}

	&.opened {
		transition: opacity .5s ease-in-out;
		opacity: 1;
		transform: translateX(0);
	}
	
}

@media( max-width: 540px ){

	#modal-newsletter {

		padding: 0px 16px;


		> div {
			padding: 16px 0;
		}

		header em br {
			display: none;
		}

		h3 {
			font-size: 18px;
			line-height: 24px;
		}
		
		button.close {
			margin-top: 8px;
			font-size: 16px;
		}

	}

}
#guia-lnd section.outros-trabalhos {

	max-width: initial;
	background: #004562;

	.headed-squares {
		margin: 0;
	}

	.header-section {

		h2, 
		em {
			color: #FEFEFE;
		}

		h2 strong {

			position: relative;
			white-space: nowrap;
			display: inline-block;
			margin-left: 5px;

			span {
				position: relative;
				z-index: 10;
				color: #ffffff;
				transition: color .5s ease-in-out;
			}

			&:before {
				content: '';
				position: absolute;
				top: -3px;
				left: -5px;
				display: block;
				width: 0;
				height: 42px;
				background: #FFDD02;
				z-index: 1;
				clip-path: polygon(0 6px, 100% 0, 100% 100%, 0% 100%);
				transition: width .5s ease-in-out;
			}
		
		}
	
	}

	&.active .header-section h2 {
		span {
			color: #333333;
		}
		strong:before {
			width: calc( 100% + 10px );
		}
	}
	
}

// Titles
@keyframes texto1 {
  0% { opacity: 0; }
  2.3391812865% { opacity: 1; }
  31.5789473684% { opacity: 1; }
  33.3333333333% { opacity: 0; }
  100% { opacity: 0; }
}
@keyframes texto1Intro {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes texto2 {
  0% { opacity: 0; }
  33.3333333333% { opacity: 0; }
  35.6725146199% {opacity: 1; }
  64.9122807018% {opacity: 1; }
  66.6666666667% {opacity: 0; }
  100% { opacity: 0; }
}
@keyframes texto3 {
  0% { opacity: 1; }
  2.3391812865% { opacity: 0; }
  66.6666666667% { opacity: 0; }
  69.005847953216% { opacity: 1; }
  98.2456140351% { opacity: 1; }
  100% { opacity: 1; }
}
@keyframes texto3Intro {
  0% { opacity: 0; }
  100% { opacity: 0; }
}

// Floats
@keyframes float1 {
  0% { opacity: 0; transform:scale(0.8) }
  2.3391812865% { opacity: 1; transform:scale(1) }
  31.5789473684% { opacity: 1; transform:scale(1) }
  33.3333333333% { opacity: 1; transform:scale(0.8) }
  35.6725146199% { opacity: 0; transform:scale(0.8) }
  100% { opacity: 0; transform:scale(0.8) }
}
@keyframes float1Intro {
  0% { opacity: 0; transform:scale(0.8) }
  100% { opacity: 1; transform:scale(1) }
}
@keyframes float2 {
  0% { opacity: 0; transform:scale(0.8) }
  33.3333333333% { opacity: 0; transform:scale(0.8) }
  35.6725146199% {opacity: 1; transform:scale(1) }
  64.9122807018% {opacity: 1; transform:scale(1) }
  66.6666666667% {opacity: 1; transform:scale(0.8) }
  69.005847953216% {opacity: 0; transform:scale(0.8) }
  100% { opacity: 0; transform:scale(0.8) }
}
@keyframes float3 {
  0% { opacity: 1; transform:scale(0.8) }
  2.3391812865% { opacity: 0; transform:scale(0.8) }
  66.6666666667% { opacity: 0; transform:scale(0.8) }
  69.005847953216% { opacity: 1; transform:scale(1) }
  98.2456140351% { opacity: 1; transform:scale(1) }
  100% { opacity: 1; transform:scale(0.8) }
}
@keyframes float3Intro {
    0% { opacity: 0; }
    100% { opacity: 0; }
}

// fundos
@keyframes fundo1 {
  0% { opacity: 0; }
  2.3391812865% { opacity: 1; }
  33.3333333333% { opacity: 1; }
  35.6725146199% { opacity: 0; }
  100% { opacity: 0; }
}
@keyframes fundo1Intro {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fundo2 {
  0% { opacity: 0; }
  33.3333333333% { opacity: 0; }
  35.6725146199% {opacity: 1; }
  66.6666666667% {opacity: 1; }
  69.005847953216% {opacity: 0; }
  100% { opacity: 0; }
}
@keyframes fundo3 {
  0% { opacity: 1; }
  2.3391812865% { opacity: 0; }
  66.6666666667% { opacity: 0; }
  69.005847953216% { opacity: 1; }
  100% { opacity: 1; }
}
@keyframes fundo3Intro {
    0% { opacity: 0; }
    100% { opacity: 0; }
}

// Hero
@keyframes heroPicture1 {
  0% { width: 50% }
  40% { width: 0% }
  100% { width: 0% }
}
@keyframes heroMobPicture1 {
  0% { height: 50% }
  40% { height: 0% }
  100% { height: 0% }
}
@keyframes heroPicture2 {
  0% { width: 50%; height: 500px; }
  40% { width: 100%; height: 500px; transform:translateY(0) }
  60% { width: 100%; height: 470px; transform:translateY(-55px) }
  100% { width: 100%; height: 470px; transform:translateY(-55px) }
}
@keyframes heroMobPicture2 {
  0% { height: 50% }
  40% { height: 100% }
  60% { height: 70% }
  100% { height: 70% }
}
@keyframes heroTitle {
  0% { left: 388px; opacity: 0; }
  5% { left: 388px; opacity: 0; }
  40% { left: 45px; opacity: 1; }
  100% { left: 45px; opacity: 1; }
}
@keyframes heroMobTitle {
  0% { left: 90%; opacity: 0; }
  50% { left: 90%; opacity: 0; }
  70% { left: 50%; opacity: 1; }
  100% { left: 50%; opacity: 1; }
}
@keyframes heroContent {
  0% { opacity: 0; }
  40% { opacity: 0; }
  70% { opacity: 1; }
  100% { opacity: 1; }
}
@keyframes heroMobContent {
  0% { opacity: 0; }
  20% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 1; }
}



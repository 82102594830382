#guia-lnd section.classificacao {

	max-width: initial;
	background: #E5007E;

	.swiper-wrapper {
		display: flex !important;
	}

	.content-wrapper {
		max-width: 970px;
		margin: 0 auto;
	}

	.header-section {
		h2, 
		em {
			color: #FEFEFE;
		}
	}

	.slider-item {

		display: flex;
		width: 100%;
		background: #FEFEFE;

		h3 {
			margin: 0 0 16px;
		}

		p {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #6D7280;
		}

		.icon-wrapper,
		> div {
			display: flex;
			justify-content: center;
		}

		.icon-wrapper {
			align-items: center;
			width: 224px;
			background: #FFDD02;
		}

		> div {
			flex: 1;
			flex-direction: column;
			align-items: flex-start;
			padding: 40px;
		}

		&.swiper-slide {
			height: initial !important;
		}
	
	}
	
}

@media( max-width: 767px ){
	
	#guia-lnd section.classificacao .slider-item {

		flex-direction: column;

		> div {
			justify-content: flex-start;
			padding: 16px;
		}

		.icon-wrapper {

			width: 100%;
			height: 206px;

			&.garrafa svg {
				width: 82px;
			}
			&.paes svg {
				transform: translateY(-11px)
			}
		
		}
	
	}
	
}


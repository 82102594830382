#guia-lnd section.cadastre-se {

	> div {

		position: relative;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;
		height: 377px;
		padding-right: 5.05%;
		padding-left: 3.08219178082%;
		color: #FEFEFE;

		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #009EE2;
			clip-path: polygon(0 0, 100% 18px, 100% calc(100% - 18px), 0% 100%);
			z-index: 1;
		}

		div {
			position: relative;
			max-width: 508px;
			z-index: 10;
		}

		h2 {
			max-width: 450px;
			color: #FEFEFE;
			font-family: 'Archivo';
			font-size: 26px;
			font-weight: 700;
			line-height: 30px;
			text-align: left;
		}

		button {

			margin-top: 24px;

			span {
				font-size: 20px;
			}
		
		}

		.graph {

			position: absolute;
			top: 50%;
			left: 1.6267123288%;
			width: 47.9452054795%;
			z-index: 10;
			transform: translateY(-57.5%);

			span {

				padding-top: 74.4642857143%;

				&:before {
					background-image: url('../images/ta-na-mesa.png');
				}
			
			}

		}

	}

}

@media( max-width: 1230px ){
	
	#guia-lnd section.cadastre-se > div div {
		width: 48%;
	}
	
}

@media( max-width: 768px ){
	
	#guia-lnd section.cadastre-se {

		padding-top: 86px;

		> div {

			height: auto;
			padding-top: 142px;
	    	padding-bottom: 35px;

			.graph {
				top: 0;
				left: 50%;
				width: 278px;
				transform: translate(-50%,-39%);
			}
			
			div {
				width: 100%;
				max-width: initial;
			}

		}
	
	}

}

@media( max-width: 480px ){

	#guia-lnd section.cadastre-se > div {

		padding: 138px 16px 16px;
	
		h2 {
			font-size: 20px;
			line-height: 30px;
		}

		button {

  			margin-top: 16px;

  			&,
  			span {
  				width: 100%;
  				justify-content: center;
  			}
		
		}

		&:before {
			clip-path: none;
		}
	
	}

}


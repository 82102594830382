#guia-lnd section.verdades {

	padding-bottom: 56px;

	.swiper-wrapper {
		display: flex !important;
	}

	.slider-item {

		display: flex;
		flex-direction: column;
		width: 379px;

		h3 {
			display: flex;
			align-items: center;
			min-height: 104px;
			padding: 12px 24px;
			margin: 0;
			background: #004562;
			font-weight: bold;
			font-size: 20px;
			line-height: 22px;
			color: #FEFEFE;
		}

		p {
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			color: #6D7280;
		}

		div {
			flex: 1;
			padding: 16px 24px;
			border: 1px solid #E3E4E8;
			border-top: 0;
		}

		&.swiper-slide {
			height: initial !important;
		}
	
	}

	footer {

		display: flex;
		flex-direction: column;
		align-items: center;

		cite {

			font-weight: 400;
			font-size: 18px;
			line-height: 27px;
			text-align: center;
			font-style: normal;
			color: #6D7280;

			a {
				color: inherit;
			}
		
		}
	
	}
	
}

